<template>
  <div class="wap-product-detail">
    <headerBar :title="$t('chong-zhi-ji-lu')" @back="back"></headerBar>
    <List
      v-if="logList.length > 0"
      v-model="loading"
      :finished="finished"
      :finished-text="$t('common.noMore')"
      @load="onLoad"
    >
      <div class="detail-box" v-for="(item, i) in logList" :key="i">
        <div class="line flex-center-between">
          <div class="label">{{ $t('shi-jian-0') }}</div>
          <div class="value">{{ item.CreatedAt }}</div>
        </div>
        <div class="line flex-center-between">
          <div class="label">{{ $t('shu-liang') }}</div>
          <div class="value">{{ item.Amount }}</div>
        </div>
        <div class="line flex-center-between">
          <div class="label">{{ $t('zhuang-tai-0') }}</div>
          <div class="value">{{ getStatusName(item.Status) }}</div>
        </div>
      </div>
    </List>
  </div>
</template>
<script>
import headerBar from '@/components/header'
import { List } from 'vant'
import { rechargeLog } from '@/api/user'
export default {
  components: {
    headerBar,
    List
  },
  data() {
    return {
      logList: [],
      page: {
        page: 1,
        limit: 10
      },
      isInit: false,
      loading: false,
      isLoading: false,
      finished: false,
      totalPage: 1
    }
  },
  computed: {},
  mounted() {
    this.init()
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    onLoad() {
      this.page.page += 1
      this.init(true)
    },
    init(isAdd) {
      this.loading = true
      rechargeLog({
        current: this.page.page,
        pageSize: this.page.limit
      })
        .then((res) => {
          if (isAdd) {
            this.logList = this.logList.concat(res.data.Items)
          } else {
            this.logList = res.data.Items
          }
          this.totalPage = res.data.Pagination
            ? res.data.Pagination.totalPage
            : 1
          if (this.totalPage <= this.page.page) {
            this.finished = true
          }
          this.isInit = true
          this.loading = false
        })
        .catch((err) => {
          this.isInit = true
          this.loading = false
        })
    },
    getStatusName(status) {
      let name = ''
      switch (status + '') {
        case '0':
          name = this.$t('dai-shen-he')
          break
        case '1':
          name = this.$t('yi-tong-guo')
          break
        case '2':
          name = this.$t('yi-ju-jue')
          break
      }
      return name
    }
  }
}
</script>